import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';

//Component Import
import ButtonNotUnderlined from '../components/ButtonNotUnderlined';
import Label from '../components/Label';
import Squiggle from '../components/squiggle';
import linkStripper from '../utils/link-stripper';

//TEMP DATA IMPORT ---- REMOVE
import tempServicesListData from '../utils/temp/servicesListData.json';

const ColumnOuterContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  font-family: DM Sans;
`;

const ColumnContainer = styled(motion.div)`
  display: flex;
  width: calc(70% + 20px);
  max-width: 1800px;
  justify-content: space-between;
  @media (max-width: 1100px) {
    width: calc(90% + 20px);
  }
  @media (max-width: 865px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const ColumnInnerContainer = styled(motion.div)`
  @media (max-width: 865px) {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
`;

const Column = styled(motion.div)`
  max-width: 250px;
  @media (max-width: 865px) {
    max-width: 100%;
  }
`;

const ColumnTitle = styled(motion.div)`
  font-weight: 600;
  font-size: 14px;

  line-height: 59px;
  color: #aaaaaa;
  margin-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;
  @media (max-width: 865px) {
    color: #404040;
    font-size: 34px;
    font-weight: 800;
  }
`;

const ServiceBlock = styled(motion.div)`
  margin-left: 10px;
  margin-right: 10px;
`;

const ServiceBlockTitle = styled(motion.div)`
  margin-bottom: 30px;
`;

const ServiceBlockLinkContainer = styled(motion.div)`
  border-width: 0px;
  border-color: #e5e5e5;
  border-style: solid;
  border-bottom-width: 1px;
  padding-bottom: 10px;
  margin-bottom: 60px;
`;

const ServiceBlockLink = styled(motion.a)``;

const LinkTitle = styled(motion.div)`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #aaaaaa;
  margin-bottom: 15px;
`;

const TitleContentOuter = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
`;

const TitleContent = styled(motion.div)`
  width: 70%;
  max-width: 1800px;
  @media (max-width: 1100px) {
    width: 90%;
  }
`;

const TitleContentInner = styled(motion.div)``;

const Title = styled(motion.h2)`
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  color: #404040;
  max-width: 550px;
  margin-top: 35px;
  font-style: normal;
  font-family: DM Serif Text;
`;

const Subtitle = styled(motion.div)`
  font-weight: 600;
  font-size: 18px;
  line-height: 36px;
  letter-spacing: 0.01em;
  color: #404040;
  max-width: 800px;
  margin-top: 25px;
`;

const ServicesListExpandedContainer = styled(motion.div)`
  position: relative;
  overflow: hidden;
`;

const LeftSquiggleContainer = styled(motion.div)`
  position: absolute;
  top: 275px;
  margin-left: -250px;
  @media (max-width: 865px) {
    display: none;
  }
`;

const RightSquiggleContainer = styled(motion.div)`
  position: absolute;
  top: 150px;
  right: 0;
  margin-right: -150px;
  @media (max-width: 1000px) {
    display: none;
  }

  @media (max-width: 865px) {
    display: block;
    margin-top: 200px;
  }
`;

const container = {
  hidden: { opacity: 1, scale: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0,
      when: 'beforeChildren',
      staggerChildren: 0.15,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

export class ServicesListExpanded extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };

  render() {
    return (
      <ServicesListExpandedContainer>
        <TitleContentOuter>
          <TitleContent>
            <TitleContentInner>
              <Label text={'Expertise'} color={'#4758eb'} />
              <Title>We forge solutions tailored to your business needs.</Title>
              <Subtitle>
                As a full service agency, we are able to use our combined
                expertise in a range of digital marketing specialisms to create
                multi-layered campaigns and strategies.
              </Subtitle>
            </TitleContentInner>
          </TitleContent>
        </TitleContentOuter>
        <VisibilitySensor
          active={this.state.visibilitySensorActive}
          onChange={this.handleVisibilityChange}
          partialVisibility
          minTopValue={250}
        >
          <ColumnOuterContainer>
            <ColumnContainer>
              {tempServicesListData.data.map((service, index) => (
                <ColumnInnerContainer key={index}>
                  <Column
                    variants={container}
                    initial="hidden"
                    animate={this.state.visible ? 'visible' : 'hidden'}
                  >
                    <ColumnTitle>{service.title}</ColumnTitle>
                    {service.blocks.map((block, index) => (
                      <ServiceBlock key={index} variants={item}>
                        <ServiceBlockTitle>
                          <ButtonNotUnderlined
                            color={'#000'}
                            arrowColor={'#4758eb'}
                            buttonText={block.title}
                          />
                        </ServiceBlockTitle>
                        <ServiceBlockLinkContainer>
                          {block.items.map((item, index) => (
                            <ServiceBlockLink
                              key={index}
                              href={linkStripper(item.link)}
                            >
                              <LinkTitle whileHover={{ color: '#000' }}>
                                {item.title}
                              </LinkTitle>
                            </ServiceBlockLink>
                          ))}
                        </ServiceBlockLinkContainer>
                      </ServiceBlock>
                    ))}
                  </Column>
                </ColumnInnerContainer>
              ))}
            </ColumnContainer>
          </ColumnOuterContainer>
        </VisibilitySensor>
      </ServicesListExpandedContainer>
    );
  }
}

export default ServicesListExpanded;
